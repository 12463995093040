<script setup lang="ts">
import type { IBranch } from '@/modules/procedure/types/bundle'
import { EventName } from '@/modules/trevo/types/enum'

const props = defineProps({
  branch: {
    type: Object as PropType<IBranch>,
    required: true,
  },

  groupIndex: {
    type: Number,
    default: 0,
  },
})

const periodsFirstOption = ref([])

const form = ref({
  firstOption: {
    date: '',
    time: '',
  },
})

function fillPeriodsByOption(newDate: string, periodsRef: Ref) {
  if (!newDate)
    return

  const dayName = new Date(newDate).toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase()

  if (!props.branch.laboratoryBranchOpeningHours || (props.branch.laboratoryBranchOpeningHours && !Object.keys(props.branch.laboratoryBranchOpeningHours).includes(dayName))) {
    periodsRef.value = getSlotsByOpeningHours({ start: '08:00', end: '18:00' })
  }
  else {
    const openingHours = props.branch.laboratoryBranchOpeningHours[dayName][0]

    periodsRef.value = getSlotsByOpeningHours(openingHours)
  }
}

function disabledWeekDays(openingHours: any) {
  if (!openingHours || !Object.values(openingHours).some(value => value.length))
    return [0]

  const days = []

  const values = Object.values(openingHours)

  for (const index in values) {
    if (!values[index].length)
      days.push(Number.parseInt(index))
  }

  return days
}

watch(
  () => form.value.firstOption.date,
  (newDate) => {
    const { bundleSelected } = useCart()

    const laboratory = bundleSelected.value.bundle.find(({ laboratoryId }: any) => laboratoryId === props.branch.laboratoryId)

    track(EventName.SelectSchedule, {
      tagAtHome: props.branch.laboratoryBranchAtHome,
      tagNeedSchedule: props.branch.laboratoryBranchNeedSchedule,
      laboratoryName: laboratory?.laboratoryName,
      branchName: props.branch.laboratoryBranchName,
      dateSchedule: new Date(newDate).toLocaleDateString('pt-BR'),
    })

    fillPeriodsByOption(newDate, periodsFirstOption)
  },
)

const { schedules } = useSchedule()
const { bundleSelected } = useCart()
const showCalendar = computed(() => {
  const slugs = bundleSelected.value.scheduleGroups[props.groupIndex].scheduleGroups.map(({ procedureDisplaySlug }: any) => procedureDisplaySlug)

  return !!props.branch.procedures.find(({ needSchedule, procedureDisplaySlug }: any) => needSchedule && slugs.includes(procedureDisplaySlug))
})

if (showCalendar.value) {
  schedules.value[props.branch.laboratoryBranchId] = form.value
}

const blockDays = computed(() => bundleSelected.value.scheduleGroups[props.groupIndex].scheduleBlockDays)
</script>

<template>
  <div v-if="showCalendar">
    <div class="flex flex-row gap-3">
      <InputDate
        id="firstOptionDate"
        v-model="form.firstOption.date"
        :min-date="blockDays ? new Date(new Date().setDate(new Date().getDate() + blockDays)) : undefined"
        :disabled-week-days="disabledWeekDays(branch.laboratoryBranchOpeningHours)"
        label="Data sugerida"
        class=" flex-1"
      />

      <InputSelect
        id="firstOptionHours"
        v-model="form.firstOption.time"
        label="Horário"
        :disabled="!form.firstOption.date"
        :options="periodsFirstOption"
        class=" flex-1"
      />
    </div>

    <div
      class="flex items-center border-t border-b border-information-40 border-dashed text-information-40 py-1 px-4 xl:px-8 bg-information-99 mt-4 xl:-mx-8 -mx-4"
    >
      <Icon name="material-symbols:info-outline" class="h-6 w-6 min-w-6 mr-3" />

      <div>
        <strong>
          Importante:
        </strong>
        <p>
          <strong>
            Seu pagamento com cartão de crédito só será efetivado após a confirmação deste agendamento com o
            laboratório.
          </strong>
          Você receberá a confirmação pelo WhatsApp e e-mail cadastrados, além de poder conferir no site, na Área do
          Usuário.
        </p>
      </div>
    </div>
  </div>
</template>
