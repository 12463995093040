<script lang="ts" setup>
import type { ICartDetail } from '@/modules/cart/types/cart.d'
import type { IBranch, IBundle, IBundleItem } from '@/modules/procedure/types/bundle.d'
import type { IProcedure } from '@/modules/procedure/types/procedure.d'
import { CHECKOUT_STEPS } from '@/modules/checkout/types/checkout.enum'

import { LocalStorageKey } from '@/modules/core/enums'

import { EventName } from '@/modules/trevo/types/enum'

const props = defineProps({
  bundle: {
    type: Array as PropType<IBundleItem[]>,
    default: () => [],
  },

  isCheckout: {
    type: Boolean,
    default: true,
  },

  isRounded: {
    type: Boolean,
    default: false,
  },

  showDetails: {
    type: Boolean,
    default: true,
  },

  enableRemoveProcedure: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['close', 'handleShowPromo'])

const discountByBundle = computed(() => {
  if (!props.bundle.length)
    return 0

  return props.bundle.reduce((acc: number, item: IBundleItem) => acc + item.laboratoryDiscount, 0)
})

const totalByBundle = computed(() => {
  if (!props.bundle.length)
    return 0

  return props.bundle.reduce((acc: number, item: IBundleItem) => acc + item.laboratoryPrice, 0)
})

const { cart, bundleSelected, branchesSelected, installments, coupons, step } = useCart()

const cartDetailsByLaboratory = computed(() => {
  const details: ICartDetail[] = []

  if (props.bundle.length) {
    return props.bundle.map((item: IBundleItem) => ({
      labId: item.laboratoryId,
      labLogo: item.laboratoryLogo,
      labName: item.laboratoryName,
      labBranchName: null,
      labBranchProcedureName: null,
      labBranchProcedurePrice: item.laboratoryPrice,
      discount: item.laboratoryDiscount.toString(),
    }))
  }

  if (!cart.value || !cart.value.cartDetails)
    return []

  cart.value.cartDetails.forEach((item: ICartDetail) => {
    if (!details.find(lab => lab.labId === item.labId))
      details.push(item)
  })

  return details
})

const { removeSlug, procedures } = useProcedures()

async function handleRemoveSlug(procedure: ICartDetail) {
  track(EventName.ExamRemove, {
    displayName: procedure.displayName,
    procedureDisplayId: procedure.procedureId,
  })

  removeSlug(procedure.procedureDisplaySlug)

  if (useRoute().name === 'checkout') {
    cart.value = {
      ...cart.value,
      cartDetails: [],
      lastStep: CHECKOUT_STEPS.BUDGET,
    }

    bundleSelected.value = {} as IBundle
    sessionStorage.removeItem('BundleSelected')

    if (!useProcedureSlug().value.length)
      return await navigateTo(`/${useLocationParam().value}`)

    return await navigateTo(`/${useLocationParam().value}/exames?exames=${useProcedureSlug().value.join(',')}`)
  }

  emit('close')

  if (useRoute().name === 'checkout')
    await navigateTo(`/${useLocationParam().value}/exames?exames=${useProcedureSlug().value.join(',')}`)
}

const showCouponToast = ref(false)

function proceduresByLaboratoryId(laboratoryId: string) {
  if (Object.keys(props.bundle).length) {
    const bundle = props.bundle
      .find((item: IBundleItem) => item.laboratoryId === laboratoryId)

    return bundle?.procedures.map((procedure: IProcedure) => ({
      discount: bundle.laboratoryDiscount,
      displayName: procedure.procedureName,
      labBranchProcedurePrice: procedure.price,
      procedureId: procedure.procedureId,
      procedure: procedure.procedureDisplaySlug,
    }))
  }

  if (!cart.value || !cart.value.cartDetails)
    return []

  return cart.value.cartDetails.filter((procedure: any) => procedure.labId === laboratoryId)
}

const { $filters } = useNuxtApp()
function getAddressByLabBranchId(branchId: string) {
  if (!branchesSelected.value.length)
    return '-'

  const branch = branchesSelected.value.find((branch: IBranch) => branch.laboratoryBranchId === branchId)

  if (branch?.laboratoryBranchAtHome) {
    return 'Coleta domiciliar'
  }
  else {
    return $filters.address({
      address: branch?.laboratoryBranchAddressOne as string,
      city: branch?.laboratoryBranchCity as string,
      district: branch?.laboratoryBranchDistrict as string,
      number: branch?.laboratoryBranchNumber as string,
    })
  }
}

function getPriceByLaboratory(laboratoryId: string) {
  return proceduresByLaboratoryId(laboratoryId)
    ?.reduce((acc: number, item: any) => acc + item.labBranchProcedurePrice, 0)
}

function getDiscountByLaboratory(laboratoryId: string) {
  if (props.bundle.length) {
    return proceduresByLaboratoryId(laboratoryId)
      ?.reduce((acc: number, item: any) => acc + Number.parseFloat(item.discount), 0) || 0
  }

  return cart.value.discount
}

function getBranchByLaboratoryId(labId: string) {
  if (!branchesSelected.value.length)
    return ''

  return branchesSelected.value.find((branch: IBranch) => branch.laboratoryId === labId)
}

const installmentsByBundle = ref()
watch(() => props.bundle, async () => {
  if (!props.bundle.length)
    return

  installmentsByBundle.value = await useCartUpdateInstallments(true, totalByBundle.value - discountByBundle.value)
}, { immediate: true, deep: true })

const showBranchSidesheet = ref(false)

const accessToken = useLocalStorage(LocalStorageKey.AccessToken, '')
onNuxtReady(async () => {
  if (accessToken.value) {
    await useCartUpdateCoupons()

    if (coupons.value.length)
      showCouponToast.value = true
  }
})
</script>

<template>
  <div>
    <div :class="props.showDetails ? 'block' : 'xl:block hidden'">
      <div v-for="laboratory in cartDetailsByLaboratory" :key="laboratory.labId">
        <TAccordion id="cart-total">
          <template #header>
            <div class="flex items-center justify-between">
              <div class="flex items-center gap-4">
                <Img
                  :src="laboratory.labLogo" :alt="`Logo do laboratório ${laboratory.labName}`"
                  classes="w-10 h-10 rounded-lg"
                />
                <div class="flex flex-col">
                  <h4 class="text-base font-bold leading-6 text-primary-40">
                    {{ laboratory.labName }}
                  </h4>
                  <span class="text-neutral-variant-30 text-sm leading-5">
                    {{ proceduresByLaboratoryId(laboratory.labId).length }} de {{ procedures.length }}
                  </span>
                </div>
              </div>

              <div class="flex flex-col items-center text-primary-20 leading-6">
                <h4 v-if="getDiscountByLaboratory(laboratory.labId)" class="text-base font-semibold line-through">
                  {{ $filters.currency(getPriceByLaboratory(laboratory.labId)) }}
                </h4>

                <h4 class="font-normal text-xl">
                  {{ $filters.currency(getPriceByLaboratory(laboratory.labId)
                    - getDiscountByLaboratory(laboratory.labId)) }}
                </h4>
              </div>
            </div>
          </template>

          <template #content>
            <div>
              <div
                v-if="isCheckout && step.index > 6"
                class="bg-success-95 xl:px-8 px-4 py-1"
                :class="laboratory.scheduleOptions ? 'text-tertiary-30 bg-tertiary-99' : 'text-success-30 bg-success-99'"
              >
                <span
                  class="text-base leading-6"
                >
                  {{ laboratory.scheduleOptions ? 'Aguardando confirmação de agendamento' : 'Liberado para realização' }}
                </span>
              </div>

              <div
                v-for="(procedure, index) in proceduresByLaboratoryId(laboratory.labId)" :key="index"
                class="text-primary-40 py-2 px-4 xl:px-8"
              >
                <div class="flex justify-between items-center">
                  <p class="text-base w-full">
                    {{ procedure.displayName }}
                  </p>

                  <div class="flex items-center gap-4">
                    <span class="text-base">
                      {{ $filters.currency(procedure.labBranchProcedurePrice) }}
                    </span>

                    <Icon
                      v-if="props.enableRemoveProcedure"
                      name="mdi:close"
                      class="w-6 h-6 cursor-pointer"
                      @click="handleRemoveSlug(procedure)"
                    />
                  </div>
                </div>

                <div class="flex justify-between items-end">
                  <div class="flex flex-col">
                    <div v-if="isCheckout && branchesSelected.length" class="flex items-center gap-4 mt-2">
                      <Icon name="mdi:map-marker-outline" class="w-6 h-6" width="14" height="20" />

                      <p class="flex flex-col text-sm leading-5 py-2">
                        <span class="text-primary-40">
                          {{
                            getAddressByLabBranchId(procedure.labBranchId)
                          }}
                        </span>

                        <span v-if="isCheckout && !procedure.scheduleOptions" class="text-neutral-variant-30 font-bold">
                          Coleta : {{ $filters.openingHours(getBranchByLaboratoryId(laboratory.labId)?.laboratoryBranchOpeningHours) }}
                        </span>
                      </p>
                    </div>

                    <div class="flex justify-between items-center">
                      <template v-if="isCheckout && procedure.scheduleOptions">
                        <div class="flex items-center gap-4 xl:mr-4">
                          <Icon name="mdi:calendar-outline" class="w-[25px] h-[21px] text-primary-20" height="18" width="20" />

                          <div class="flex flex-col">
                            <span class="text-primary-20 font-semibold text-xs leading-4">
                              Data sugerida
                            </span>

                            <span class="text-base text-neutral-variant-30 leading-6">
                              {{ procedure.scheduleOptions?.firstOption.date }}
                            </span>
                          </div>
                        </div>

                        <div class="flex flex-col">
                          <span class="text-primary-20 font-semibold text-xs leading-4">
                            Horário
                          </span>

                          <span class="text-base text-neutral-variant-30 leading-6">
                            {{ procedure.scheduleOptions?.firstOption.time }}
                          </span>
                        </div>
                      </template>
                    </div>
                  </div>

                  <span v-if="step.index < 8 && props.isCheckout && branchesSelected.length" class="text-primary-50 text-xs font-semibold cursor-pointer" @click="showBranchSidesheet = true">
                    Trocar
                  </span>
                </div>
              </div>
            </div>
          </template>
        </TAccordion>
      </div>
    </div>

    <CartPromoResume />

    <teleport to="body">
      <div class="absolute top-[68px] w-full flex justify-center z-10">
        <CartCupomToast
          v-if="showCouponToast && coupons.length && step.index <= 6" :promo="coupons[0]"
          @promo-selected="usePromoModalOpen" @close="showCouponToast = false"
        />
      </div>
    </teleport>
    <div
      v-if="(cart && cart.discount) || discountByBundle"
      class="flex justify-between bg-neutral-variant-96 font-bold text-base leading-6 px-4 xl:px-8 py-4 text-primary-20"
    >
      <span>Descontos</span>
      <span>{{ `- ${$filters.currency(cart.discount || discountByBundle)}` }}</span>
    </div>

    <div
      v-if="cart && Object.keys(installments).length || installmentsByBundle"
      class="border-t border-neutral-60 items-center bg-neutral-80 text-primary-40 px-4 xl:px-8 py-1 text-base xl:text-xl font-bold xl:font-extrabold"
      :class="isRounded ? 'rounded-b-lg' : ''"
    >
      <div class="flex items-center justify-between">
        <span> Total </span>
        <div class="flex items-end flex-col">
          <span v-if="installmentsByBundle">{{ $filters.currency(totalByBundle - discountByBundle) }} ou</span>
          <span v-else>{{ $filters.currency(cart.totalPrice - cart.discount) }} ou</span>

          <span v-if="installmentsByBundle">{{
            installmentsByBundle.installmentOptions[installmentsByBundle.maxInstallments - 1].text }} sem juros</span>
          <span v-else>{{ installments.installmentOptions[installments.maxInstallments - 1].text }} sem juros</span>
        </div>
      </div>
    </div>

    <ProcedureBranchSidesheet
      :show="showBranchSidesheet"
      hide-change-branch
      @close="showBranchSidesheet = false"
    />
  </div>
</template>
